import { ModuleWithProviders, NgModule } from "@angular/core";
import { IOAuthService } from "@app/shared/authentication/angular-oauth-oidc/ioauth-service";
import { MockOAuthService } from "@app/shared/authentication/angular-oauth-oidc/mock.oauth-service";
import { IHypecastOAuthService } from "@app/shared/authentication/services/ihypecast-oauth.service";
import { MockHypecastOAuthService } from "@app/shared/authentication/services/mock.hypecast-oauth.service";

import { createDefaultLogger, createDefaultStorage } from "./angular-oauth-oidc/factories";
import {
  OAuthNoopResourceServerErrorHandler,
  OAuthResourceServerErrorHandler,
} from "./angular-oauth-oidc/interceptors/resource-server-error-handler";
import { OAuthService } from "./angular-oauth-oidc/oauth-service";
import { DefaultHashHandler, HashHandler } from "./angular-oauth-oidc/token-validation/hash-handler";
import { NullValidationHandler } from "./angular-oauth-oidc/token-validation/null-validation-handler";
import { ValidationHandler } from "./angular-oauth-oidc/token-validation/validation-handler";
import { IOAuthStorage, OAuthLogger } from "./angular-oauth-oidc/types";
import { UrlHelperService } from "./angular-oauth-oidc/url-helper.service";
import { HypecastCredentialsAuthenticationGuard } from "./guards/hypecast-credentials-auth.guard";
import { HypecastAuthenticationService } from "./services/authentication.service";
import { HypecastOAuthService } from "./services/hypecast-oauth.service";

@NgModule()
export class HypecastAuthenticationModule {
  static forRoot(
    validationHandlerClass: any = NullValidationHandler
  ): ModuleWithProviders<HypecastAuthenticationModule> {
    if (
      window.location.href.includes("coyo-plugin") ||
      window.location.href.includes("staffbase-plugin") ||
      window.location.href.includes("iframe-plugin") ||
      window.location.href.includes("unily-plugin")
    ) {
      return {
        ngModule: HypecastAuthenticationModule,
        providers: [
          { provide: IOAuthService, useClass: MockOAuthService },
          UrlHelperService,
          { provide: IHypecastOAuthService, useClass: MockHypecastOAuthService },
          HypecastCredentialsAuthenticationGuard,
          HypecastAuthenticationService,
          { provide: OAuthLogger, useFactory: createDefaultLogger },
          { provide: IOAuthStorage, useFactory: createDefaultStorage },
          { provide: ValidationHandler, useClass: validationHandlerClass },
          { provide: HashHandler, useClass: DefaultHashHandler },
          {
            provide: OAuthResourceServerErrorHandler,
            useClass: OAuthNoopResourceServerErrorHandler,
          },
        ],
      };
    }
    return {
      ngModule: HypecastAuthenticationModule,
      providers: [
        { provide: IOAuthService, useClass: OAuthService },
        UrlHelperService,
        { provide: IHypecastOAuthService, useClass: HypecastOAuthService },
        HypecastCredentialsAuthenticationGuard,
        HypecastAuthenticationService,
        { provide: OAuthLogger, useFactory: createDefaultLogger },
        { provide: IOAuthStorage, useFactory: createDefaultStorage },
        { provide: ValidationHandler, useClass: validationHandlerClass },
        { provide: HashHandler, useClass: DefaultHashHandler },
        {
          provide: OAuthResourceServerErrorHandler,
          useClass: OAuthNoopResourceServerErrorHandler,
        },
      ],
    };
  }

  constructor(private oauthService: IHypecastOAuthService) {}
}
