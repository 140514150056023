import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable, Subject, Subscriber } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { BaseService } from "../base/services";
import { __ } from "../functions/object.functions";
import { Tenant } from "../models/classes/Tenant";
import { ITenantService } from "./itenant.service";
import { Response } from "./local/Response";
import * as moment from "moment";

const tenant_key = "hypecast-tenant-2346cciv3gzw3o51yqn";

@Injectable()
export class TenantService extends BaseService implements ITenantService {
  tenant$: Observable<Tenant>;

  tenant: Tenant;

  private _tenant$: Subject<Tenant> = new Subject<Tenant>();

  constructor(protected httpClient: HttpClient) {
    super();

    this.tenant$ = new Observable<Tenant>((subscriber: Subscriber<Tenant>) => {
      if (!__.IsNullOrUndefined(this.tenant)) {
        subscriber.next(this.tenant);
        subscriber.complete();
      } else {
        const subdomain = this.getTenantName();
        let tenant: { tenant: Tenant; subdomain: string; expiresAt: string } = undefined;

        if (
          !window.location.href.includes("coyo-plugin") ||
          !window.location.href.includes("staffbase-plugin") ||
          !window.location.href.includes("iframe-plugin") ||
          !window.location.href.includes("unily-plugin")
        ) {
          if (localStorage !== undefined) {
            tenant = JSON.parse(localStorage.getItem(tenant_key)) as {
              tenant: Tenant;
              subdomain: string;
              expiresAt: string;
            };
          }
        }

        if (
          !__.IsNullOrUndefined(tenant) &&
          tenant.subdomain === subdomain &&
          !__.IsNullOrUndefined(tenant.expiresAt) &&
          moment(tenant.expiresAt) > moment()
        ) {
          this._tenant$.next(tenant.tenant);
          this.tenant = tenant.tenant;
          subscriber.next(this.tenant);
          subscriber.complete();
        } else {
          if (environment.environmentName === "DEV") {
            const localTenant = Object.assign(new Tenant(), {
              tenantName: environment.subdomain,
              apiUrl: environment.serverUrl,
              cdnUrl: environment.serverUrlCDN,
              clientId: environment.clientId,
              companyName: "Local tenant",
              logoUrl: "https://i0.wp.com/hypecast.one/wp-content/uploads/2021/06/white-1.png?fit=582%2C135&ssl=1",
            } as Tenant);

            this.tenant = localTenant;
            subscriber.next(localTenant);
            subscriber.complete();
          } else {
            super.addSubscription(
              this.httpClient
                .disableApiPrefix()
                .disableAccessToken()
                .disableAuthorizationInterceptor()
                .get(`${environment.masterServerUrl}tenants/${subdomain}`)
                .pipe(
                  map((response: Response<Tenant>) => {
                    if (
                      !window.location.href.includes("coyo-plugin") ||
                      !window.location.href.includes("staffbase-plugin") ||
                      !window.location.href.includes("iframe-plugin") ||
                      !window.location.href.includes("unily-plugin")
                    ) {
                      localStorage.setItem(
                        tenant_key,
                        JSON.stringify({
                          tenant: response.data,
                          subdomain,
                        })
                      );
                    }
                    this.tenant = response.data;
                    this._tenant$.next(response.data);
                    return response.data;
                  }),
                  catchError((error: HttpErrorResponse) => {
                    throw error;
                  })
                )
                .subscribe({
                  next: (fetchedTenant: Tenant) => {
                    this.tenant = fetchedTenant;
                    subscriber.next(this.tenant);
                    subscriber.complete();
                  },
                })
            );
          }
        }
      }
    });
  }

  getTenant$(): Observable<Tenant> {
    return this.tenant$;
  }

  getTenant(): Tenant {
    return this.tenant;
  }

  getTenantName(): string {
    if (environment.environmentName === "DEV" && !__.IsNullOrUndefined(environment.subdomain)) {
      return environment.subdomain;
    }

    const domain = window.location.hostname;
    if (
      domain.indexOf(".") < 0 ||
      domain.split(".")[0] === "example" ||
      domain.split(".")[0] === "lvh" ||
      domain.split(".")[0] === "www"
    ) {
      return "";
    }
    return domain.split(".")[0];
  }

  // Removes the tenant from the local storage
  resetTenant(): void {
    sessionStorage.removeItem(tenant_key);
    localStorage.removeItem(tenant_key);
  }
}
