import { MemoryStorage, MemoryStorageMock } from "./types";

export function createDefaultLogger() {
  return console;
}

export function createDefaultStorage() {
  if (window.location.href.includes("coyo-plugin")) {
    return new MemoryStorageMock();
  }
  if (window.location.href.includes("staffbase-plugin")) {
    return new MemoryStorageMock();
  }
  if (window.location.href.includes("iframe-plugin")) {
    return new MemoryStorageMock();
  }
  if (window.location.href.includes("unily-plugin")) {
    return new MemoryStorageMock();
  }
  return typeof localStorage !== "undefined" ? localStorage : new MemoryStorage();
}
